<template>
  <input 
    @input="$emit('input', $event.target.value)" 
    :value="value" 
    class="block border border-gray-300 rounded p-2 mb-4" 
  />
</template>

<script>
export default {
  props: ['value']
}
</script>