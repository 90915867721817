<template>
  <div>
    <header class="bg-dark shadow flex flex-row justify-between items-center">
      <a href="https://clickbox.com">
        <img alt="Clickbox" src="../assets/logo-orange.png" class="inline logo ml-4">
      </a>
      <LanguageSelector v-if="!noLang" class="language-selector mr-4" />
    </header>
    <main class="flex justify-center">
      <slot/>
    </main>
  </div>
</template>

<script>
import LanguageSelector from '../components/LanguageSelector.vue'

export default {
  components: {LanguageSelector},
  props: {
     noLang: {
       type: Boolean,
       default: false,
     }
  }
}
</script>