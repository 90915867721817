<template>
  <div>
    <Button 
      v-for="locale in availableLocales" 
      :key="locale"
      v-text="locale"
      class="ml-2"
      :class="{
        'bg-brand': (locale == currentLocale),
        'bg-transparent text-brand': !(locale == currentLocale),
      }"
      @click="setLocale(locale)"
    >
    </Button>
  </div>
</template>

<script>
import Button from './Button'

export default {
  components: {Button},
  computed: {
    availableLocales() {
      return this.$i18n.availableLocales
    },
    currentLocale() {
      return this.$i18n.locale
    }
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale
    }
  }
}
</script>