<template>
  <DefaultLayout>
    <FloatingBox class="sm:w-128">
      <div class="max-w-4xl mb-4">
        <h1 class="text-2xl mb-4">{{$t('search.title')}}</h1>
        <Input v-model="trackingNumber" class="w-full" type="text" :placeholder="$t('search.placeholder')" />
        <div v-if="error" class="bg-red-200 rounded text-red-700 p-4 mb-4">
          {{$t(`error.${error}`)}}
        </div>
        <Button :disabled="!trackingNumber" class="w-full bg-dark text-white" @click="search">Search</Button>
      </div>
      <div class="text-center hover:underline">
        <a href="https://clickbox.com">
          {{$t(referred ? 'search.back-to-clickbox' : 'search.go-to-clickbox')}}
        </a>
      </div>
    </FloatingBox>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '../layouts/Default.vue'
import FloatingBox from '../components/FloatingBox'
import Input from '../components/Input.vue'
import Button from '../components/Button.vue'

export default {
  name: 'Search',
  components: {
    DefaultLayout,
    FloatingBox,
    Input,
    Button,
  },
  data() {
    return {
      error: this.$route.params?.error || null,
      trackingNumber: null
    }
  },
  computed: {
    referred() {
      return !!~document.referrer.indexOf('clickbox.com')
    }
  },
  methods: {
    search() {
      this.$router.push({ name: 'show', params: 
        { 
          trackingNumber: this.trackingNumber,
        } 
      })
      .catch(() => {
        // this.error = true
      }) 
    }
  },
  metaInfo() {
    return {
      title: this.$t('search.title'),
    }
  } 
};
</script>
